<template>
  <div class="text-container">
    Eine Standortbewertung bei Neugründung oder Kauf einer Apotheke ist der
    entscheidende erste Schritt. Die Standortbewertung gibt Ihnen eine Grundlage
    für Kalkulation Ihrer künftigen Aktivitäten.
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir führen eine Standortbewertung durch, um Sie vor der Neugründung einer Apotheke über die Grundlage Ihrer künftigen Aktivitäten zu beraten.",
      },
    ],
  },
};
</script>